import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";

const useLoginWithOTPLess = () => {
  const navigation = useRouter();
  const toast = useToast();
  const URL = `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.www.login.login_with_otp_less`;
  type OTPLessPayloadType = {
    token: any;
    timestamp: any;
    timezone: any;
    mobile: any;
    wa_number: any;
    wa_name: any;
    device: string;
    use_jwt: number;
  };
  let data = JSON.stringify({
    token: "",
    timestamp: "",
    timezone: "",
    mobile: "",
    wa_number: "",
    wa_name: "",
    device: "mobile",
  });
  // let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: URL,
  //     headers: {
  //         'Content-Type': 'application/json',
  //     },
  //     data: data
  // }
  return useMutation(async ({ payload }: { payload: OTPLessPayloadType }) => {
    console.log('payload in otpless hook: ', payload)
    try {
      const res = await axios({
        method: "post",
        //maxBodyLength: Infinity,
        url: URL,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      });
      return res;
    } catch (e) {
      const errorResponse: any = e;
      const errorMessage =
        errorResponse?.response?.data?.exception?.split(": ")[1];

      if (
        errorMessage ===
        `User  not found with this mobile no ${payload?.wa_number}`
      ) {
        toast({
          title: "Try Again",
          description: "User not found with this Mobile No.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        navigation.push("/signup");
      } else {
        console.log("useLoginWithOTPLess Error: ", e);
        toast({
          title: "Try Again",
          description: "Something Went Wrong",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        navigation.push("/login");
      }
    }
  });
};

export default useLoginWithOTPLess;
