import {useState, useEffect} from 'react';

const useResendOtpTimer = (start:number) => {
  const stop = 0;
  const [timer, setTimer] = useState(start);
  const [x, setX] = useState(true);
  useEffect(() => {
    if (timer < stop) {
      setTimer(() => stop);
      setX(() => false);
    }
  }, [timer]);
  const startTimer = () => {
    if (x) {
      const t = setInterval(() => {
        if (timer < stop) {
          setTimer(() => stop);
          clearInterval(t);
        } else {
          setTimer(p => p - 1);
        }
        return () => clearInterval(t);
      }, 1000);
    } else {
      return;
    }
  };
  const restartTimer = () => {
    setTimer(() => start);
  };

  const stopTimer = () => {
    setTimer(() => 0);
  };
  return {timer, startTimer, restartTimer, stopTimer} as {timer:number; startTimer:() => void; restartTimer:() => void; stopTimer:() => void}
};

export default useResendOtpTimer;