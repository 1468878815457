import Layout from "@/lib/Layout/Layout";
import LoginCard from "../../components/Authentication/LoginCard";
import { footerData } from "../api/footerData";
import useCheckLogin from "@/lib/hooks/useCheckLogin";
import { useRouter } from "next/router";

const Login = ({isSizer}: {isSizer?:boolean}) => {
  const {data: isLoggedIn} = useCheckLogin();
  const router = useRouter();
  if (!isLoggedIn) {

  }
  // if (isLoggedIn) {
  //   router.push("/");

  // }

  return (
    <>
      <Layout footerData={footerData}>
        <LoginCard />
      </Layout>
    </>
  );
};


export default Login;
