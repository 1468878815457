import Section from "@/lib/Layout/Wrappers/Section";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  Heading,
  Text,
  Divider,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import LoginCheck from "./LoginCheck";

const LoginCard = ({
  backArrow,
  isSizer,
}: {
  backArrow?: boolean;
  isSizer?: boolean;
}) => {
  const [verifyState, setVerifyState] = useState<"" | "email" | "mobile">("");
  return (
    <Box margin={4}>
      {backArrow && (
        <Section>
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label="go-back"
            variant="unstyled"
            onClick={() => setVerifyState("")}
          />
        </Section>
      )}

      <Flex
        minH={"70vh"}
        align={"center"}
        justify={"center"}
        borderRadius={8}
      >
        <Stack spacing={8} mx={"auto"} w={"lg"} px={4}>
          <Stack>
            <Heading fontSize={"xl"} fontWeight="regular">
              Welcome to
            </Heading>
            <Text fontSize={"2xl"} fontWeight="bold">
              <Text as="span" color="#22C55E">
                Bonatra
              </Text>{" "}
              Healthcare Private Ltd
            </Text>
            <Divider orientation="horizontal" mx={8} />
          </Stack>
          <LoginCheck
            isSizer={isSizer}
            verifyState={verifyState}
            setVerifyState={setVerifyState}
          />
        </Stack>
      </Flex>
    </Box>
  );
};

export default LoginCard;
