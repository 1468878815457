import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const useLoginWithEmail = () => {
  return useMutation(async (payload: { usr: string; pwd: string }) => {
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/login`,
      { usr: payload.usr, pwd: payload.pwd, use_jwt: 1,"device":"mobile" },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return res;
  });
};

const useGetLoggedInUser = () => {
  return useMutation(async (token: string) => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/frappe.auth.get_logged_user`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return res;
  });
};

const useRequestOTP = () => {
  return useMutation(async (mobile: string) => {
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.www.login.login_via_mobile`,
      { mobile_no: mobile },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return res;
  });
};

const useVerifyOTP = () => {
  return useMutation(async ({payload}: {payload: {otp: string, session_id: string | null}}) => {
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.www.login.verify_otp`,
      {
        otp: payload.otp,
        session_id: payload.session_id,
        use_jwt: 1,
        device: "mobile",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return res;
  });

  
};

const useResetPassword = () => {
  return useMutation(async (payload: { email: string }) => {
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/frappe.core.doctype.user.user.reset_password`,
      { user: payload.email }
    );
    return res;
  });
};

export { useLoginWithEmail, useRequestOTP, useVerifyOTP, useGetLoggedInUser, useResetPassword };
