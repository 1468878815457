import {
  Box,
  Center,
  FormControl,
  HStack,
  PinInput,
  PinInputField,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Link,
  Button,
  useColorModeValue,
  Text,
  useToast,
  Flex,
  InputGroup,
  InputRightElement,
  Divider,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  useLoginWithEmail,
  useGetLoggedInUser,
  useRequestOTP,
  useVerifyOTP,
} from "../../lib/hooks/QueryHooks/useLogin";
import useZustandStore from "@/lib/store/useZustandStore";
import { useRouter } from "next/router";
import axios from "axios";
import useAuthStore from "@/lib/store/useAuthStore";
import useResendOtpTimer from "@/lib/hooks/useResendOtpTimer";
import { logEvent } from "@/analytics";
import OTPLess from "./OTPLess";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import useGoogleAuth from "@/lib/hooks/QueryHooks/useGoogleAuth";
import useCheckLogin from "@/lib/hooks/useCheckLogin";

type FormData = {
  emailOrMobile: string;
};

const LoginCheck = ({
  verifyState,
  setVerifyState,
}: {
  isSizer?: boolean;
  verifyState: "" | "email" | "mobile";
  setVerifyState: Dispatch<SetStateAction<"" | "email" | "mobile">>;
}) => {
  const toast = useToast();
  // const { data: isLoggedIn } = useCheckLogin();
  const { mutate: loginWithEmailMutate } = useLoginWithEmail();
  const { mutate: requestOTPMutate } = useRequestOTP();
  const { mutate: verifyOTPMutate } = useVerifyOTP();
  const { mutate: googleAuthMutate } = useGoogleAuth();
  const { mutate: getLoggedInUserMutate } = useGetLoggedInUser();
  const [otp, setOtp] = useState("");
  const [emailLogin, setEmailLogin] = useState({ pwd: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({ type: "", value: "" });
  const { setUserData, setToken } = useAuthStore((state) => state);
  const { timer, restartTimer, startTimer } = useResendOtpTimer(60);
  const navigation = useRouter();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const setUser = useZustandStore((state) => state.setUser);
  const [callEffect, setCallEffect] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, [callEffect]);

  if (isLoggedIn) {
    navigation.back();
  }

  const checkEmailorMobile = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;

    if (emailRegex.test(value)) {
      return "email";
    } else if (mobileRegex.test(value)) {
      return "mobile";
    } else {
      return "";
    }
  };

  const handleLoginProcess = async (res: any) => {
    if (res.data.full_name) {
      toast({
        title: "Logged In",
        description: "Welcome back, " + res.data.full_name,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
    localStorage.setItem("token", res.data.token);
    setToken(res.data.token);
    localStorage.setItem("user", res.data.full_name);
    let localUserData = await axios.get(
      `${
        process.env.NEXT_PUBLIC_API_URL
      }/api/method/bonatra.custom.latest_customer_v2?user=${localStorage.getItem(
        "email"
      )}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    );
    setUserData(localUserData?.data?.message);
    setUser(res?.data?.message?.data?.user);
    navigation.back();
  };
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleAuthMutate(tokenResponse.access_token, {
        onSuccess: async (res: any) => {
          if (res?.data?.message?.signup) {
            googleAuthMutate(tokenResponse.access_token, {
              onSuccess: async (res: any) => {
                handleLoginProcess(res);
                setIsLoading(false);
              },
              onError: () => {
                setIsLoading(false);
                toast({
                  title: "Error",
                  description:
                    "An error occurred while signing in with Google, please try later",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                  position: "top",
                });
              },
            });
            return;
          }
          handleLoginProcess(res);
          setIsLoading(false);
        },

        onError: (error: any) => {
          setIsLoading(false);
          if (error.response.status == 409) {
            toast({
              title: "Error",
              description: "Please sign up first",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
            return;
          }
          toast({
            title: "Error",
            description:
              "An error occurred while signing in with Google, please try later",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        },
      });
    },
  });

  const handleMobileLoginSuccess = async (res: any) => {
    setIsLoading(false);
    if (res.data.full_name) {
      toast({
        title: "Logged In",
        description: `Welcome back, ${res.data.full_name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
    localStorage.setItem("token", res.data.token);
    setToken(res.data.token);
    localStorage.setItem("user", res.data.full_name);
    localStorage.setItem("email", res.data.message?.data?.user || "");
    const userData = await axios.get(
      `${
        process.env.NEXT_PUBLIC_API_URL
      }/api/method/bonatra.custom.latest_customer_v2?user=${localStorage.getItem(
        "email"
      )}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    );
    setUserData(userData?.data?.message);
    setUser(res.data.message?.data?.user);

    if (userData) {
      navigation.back();
    }
  };

  const handleMobileLoginError = () => {
    setIsLoading(false);
    toast({
      title: "Error",
      description: "Invalid OTP",
      status: "error",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };

  const handleEmailLoginSuccess = async (res: any) => {
    const jwt = res.data.token;
    setToken(jwt);
    localStorage.setItem("token", jwt);
    setCallEffect(true);
    getLoggedInUserMutate(jwt, {
      onSuccess: async (userRes: any) => {
        setIsLoading(false);

        const loggedInUser = userRes.data.message;
        if (loggedInUser.name) {
          toast({
            title: "Logged In",
            status: "success",
            description: `Welcome back, ${loggedInUser.full_name}`,
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          localStorage.setItem("email", loggedInUser.email);
          localStorage.setItem("user", loggedInUser.full_name);
          localStorage.setItem("mobile_no", loggedInUser.mobile_no);
        }
        const userData = await axios.get(
          `${
            process.env.NEXT_PUBLIC_API_URL
          }/api/method/bonatra.custom.latest_customer_v2?user=${localStorage.getItem(
            "email"
          )}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            withCredentials: true,
          }
        );
        setUserData(userData?.data?.message);
        setUser(loggedInUser.data?.user);

        if (userData) {
          navigation.back();
        }
      },
      onError: (userError: any) => {
        setIsLoading(false);
        if (userError.status_code == 401) {
          toast({
            title: "Error",
            description: "Unable to fetch user details",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      },
    });
  };

  const handleEmailLoginError = (err: any) => {
    if (err.response.status === 401) {
      setIsLoading(false);
      toast({
        title: "Error",
        description: "Invalid Credentials",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
    if (err) {
      setIsLoading(false);
      toast({
        title: "Error",
        description: err.response.data.exc_type,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleLogin: SubmitHandler<FormData> = (data, event) => {
    if ((event?.nativeEvent as any)?.submitter?.name === "submitFinal") {
      setIsLoading(true);

      if (verifyState === "mobile") {
        const payload = {
          otp: otp,
          session_id: localStorage.getItem("otp_session_id"),
        };

        verifyOTPMutate(
          { payload: payload },
          {
            onSuccess: handleMobileLoginSuccess,
            onError: handleMobileLoginError,
          }
        );
      } else {
        const loginData = { usr: loginInfo.value, pwd: emailLogin.pwd };
        loginWithEmailMutate(
          { ...loginData },
          { onSuccess: handleEmailLoginSuccess, onError: handleEmailLoginError }
        );
      }
    } else {
      onSubmit();
    }
  };

  const resendOTPHandler = () => {
    restartTimer();
    requestOTPMutate(loginInfo.value, {
      onSuccess: (data: any) => {
        toast({
          title: "OTP resent",
          status: "info",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
        localStorage.setItem("otp_session_id", data.data.message);
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Unable to send otp",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  const onSubmit = () => {
    const type = checkEmailorMobile(loginInfo.value);
    if (type == "email") {
      if (loginInfo.value) {
        setVerifyState(type);
      }
    }
    if (type == "mobile") {
      startTimer();
      requestOTPMutate(loginInfo.value, {
        onSuccess: (data: any) => {
          if (loginInfo.value) {
            setVerifyState(type);
          }
          toast({
            title: "OTP sent to your mobile",
            status: "info",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          localStorage.setItem("otp_session_id", data.data.message);
        },
        onError: (err: any) => {
          if (err.response.data.exception.includes("User not found")) {
            toast({
              title: "User not found",
              description: "Please sign up to continue",
              status: "error",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
          }
        },
      });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const validateEmailOrMobile = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;

    if (emailRegex.test(value)) {
      return true;
    } else if (mobileRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Box margin={10} rounded={"lg"} bg="black" paddingY="4" paddingX="8">
      <form onSubmit={handleSubmit(handleLogin)} id="handle_login">
        <Stack spacing={4}>
          <FormControl isInvalid={Boolean(errors.emailOrMobile)} isRequired>
            <FormLabel fontSize="xl">Email or Mobile Number</FormLabel>
            <Input
              type="text"
              variant={verifyState ? "filled" : "outline"}
              boxShadow="lg"
              {...register("emailOrMobile", {
                required: "This field is required",
                validate: (value) =>
                  validateEmailOrMobile(value) ||
                  "Please enter a valid email or mobile number",
              })}
              defaultValue=""
              onKeyDown={handleKeyDown}
              isReadOnly={!!verifyState}
              onChange={(event) => {
                setLoginInfo((prevState) => ({
                  ...prevState,
                  value: event.target.value,
                }));
              }}
              size="lg"
            />
            <FormErrorMessage>
              {errors?.emailOrMobile?.message}
            </FormErrorMessage>
          </FormControl>
          {!verifyState ? (
            <Stack spacing={4}>
              <Button
                id="authentication_login_check"
                bg={"#22C55E"}
                color={"white"}
                _hover={{
                  bg: "green.700",
                }}
                type="submit"
                name="submitInit"
                size="lg"
              >
                Submit
              </Button>
              <HStack>
                <Divider />
                <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                  or
                </Text>
                <Divider />
              </HStack>
              <OTPLess />
              <Button
                w={"full"}
                variant={"outline"}
                leftIcon={<FcGoogle />}
                size="lg"
                _hover={{
                  bg: "gray.900",
                }}
                isLoading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  handleGoogleLogin();
                }}
              >
                <Center>
                  <Text>Sign in with Google</Text>
                </Center>
              </Button>
              {/* <Button
                w={"full"}
                variant={"outline"}
                leftIcon={<MdEmail />}
                size="lg"
                isLoading={isLoading}
                onClick={() => {
                  navigation.push("/magic_link")
                }}
              >
                <Center>
                  <Text>Sign in with Magic Link</Text>
                </Center>
              </Button> */}
            </Stack>
          ) : null}
          {verifyState === "email" ? (
            <PasswordVerify
              setEmailLogin={setEmailLogin}
              isLoading={isLoading}
            />
          ) : (
            verifyState === "mobile" && (
              <MobileVerify
                setOtp={setOtp}
                isLoading={isLoading}
                resendOTPHandler={resendOTPHandler}
                seconds={timer}
              />
            )
          )}
          {
            <Stack pt={4}>
              <Text align={"center"} fontSize="lg">
                Don&#39;t have an account?{" "}
                <Link color={"#22C55E"} href="/signup" fontWeight="semibold">
                  Sign Up
                </Link>
              </Text>
            </Stack>
          }
        </Stack>
      </form>
    </Box>
  );
};

const MobileVerify = ({
  setOtp,
  isLoading,
  resendOTPHandler,
  seconds,
}: {
  setOtp: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  resendOTPHandler: () => void;
  seconds: number;
}) => {
  return (
    <Stack pt={4}>
      <FormControl>
        <Center>
          <HStack>
            <PinInput
              placeholder=""
              onChange={(value) => {
                setOtp(value);
              }}
              size="lg"
            >
              <PinInputField boxShadow="lg" />
              <PinInputField boxShadow="lg" />
              <PinInputField boxShadow="lg" />
              <PinInputField boxShadow="lg" />
              <PinInputField boxShadow="lg" />
              <PinInputField boxShadow="lg" />
            </PinInput>
          </HStack>
        </Center>
      </FormControl>
      <Stack pt={4}>
        <Button
          id="authentication_verfiy_otp"
          bg={"#22C55E"}
          color={"black"}
          _hover={{
            bg: "green.700",
          }}
          name="submitFinal"
          type="submit"
          size="lg"
          isLoading={isLoading}
          loadingText="Logging In"
          onClick={() => {
            logEvent("Authentication", "Verify OTP");
            //(window as any).fbq("trackCustom", "Verify OTP");
            if (window?.fbq && typeof window?.fbq === "function") {
              window?.fbq("trackCustom", "Buy Now");
            } else {
              console.error("Facebook Pixel not initialized or not available.");
            }
          }}
        >
          Verify
        </Button>
      </Stack>
      <Stack pt={4}>
        <Flex justify="space-between">
          <Text align={"center"} fontSize="lg">
            <Link
              color={seconds === 0 ? "#22C55E" : "#808080"}
              onClick={seconds === 0 ? resendOTPHandler : undefined}
              fontWeight="semibold"
            >
              Resend OTP
            </Link>
          </Text>
          <Text align={"center"} fontSize="lg" color="#22C55E">
            {seconds > 0 ? seconds : null}
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
};

const PasswordVerify = ({
  setEmailLogin,
  isLoading,
}: {
  setEmailLogin: Dispatch<
    SetStateAction<{
      pwd: string;
    }>
  >;
  isLoading: boolean;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Stack>
      <FormControl id="password">
        <FormLabel fontSize="xl">Password</FormLabel>
        <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            boxShadow="lg"
            onChange={(event) => {
              setEmailLogin((prevState) => ({
                ...prevState,
                pwd: event.target.value,
              }));
            }}
            size="lg"
          />
          <InputRightElement h={"full"}>
            <Button
              variant={"ghost"}
              onClick={() => setShowPassword((showPassword) => !showPassword)}
            >
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Stack pt={4}>
        <Button
          id="authentication_sign_in"
          bg={"black"}
          color={"white"}
          _hover={{
            bg: "gray.700",
          }}
          name="submitFinal"
          type="submit"
          size="lg"
          isLoading={isLoading}
          loadingText="Logging In"
          onClick={() => {
            logEvent("Authentication", "Password Login");
            //(window as any).fbq("trackCustom", "Password Login");
            if (window?.fbq && typeof window?.fbq === "function") {
              window?.fbq("trackCustom", "Buy Now");
            } else {
              console.error("Facebook Pixel not initialized or not available.");
            }
          }}
        >
          Sign in
        </Button>
        <Stack pt={4} align="center">
          <Link
            color="#22C55E"
            fontSize="lg"
            fontWeight="semibold"
            href="/login/forgot-password"
          >
            Forgot Password
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginCheck;
