import { NextApiRequest, NextApiResponse } from "next";

export type footerData = typeof footerData;
export type footerLabelData = (typeof footerData)[0];

export const footerData = [
  //     {
  //     title:'WHAT WE TREAT',
  //     labels:['Diabetes','Blood Pressure','Fitness','PCOD','Thyroid'],
  //     route: [""],
  //     queryid: [""]
  // },
  {
    title: "Quick Links",
    labels: [
      "Home",
      "All Programs & Products",
      "CGM based programs",
      "What is a CGM ?",
      "Blog",
      "About Us",
      "Login",
      "Signup",
      "How to Setup Ring ?",
    ],
    route: [
      "/",
      "/all-products",
      "/cgm-programs",
      "/continuous-glucose-monitoring",
      "https://blog.thehealthspan.ai/",
      "/about-us",
      "/login",
      "/signup",
      "/bonatra-x1-ring-faq",
    ],
    queryid: [""],
  },

  {
    title: "Bonatra x programs",
    labels: [
      "Age Reversal",
      "Metabolic Health",
      "Weight Loss",
      "Bonatra X With 2 CGM",
    ],
    route: [
      "/package/age-reversal",
      "/package/metabolic-health",
      "/package/weight-loss",
      "/package/two-cgm",
    ],
    queryid: [""],
  },
  {
    title: "All Programs & Products",
    labels: [
      "Bonatra X Programs",
      "Bonatra Long Term Reversal Packages",
      "Bonatra Supplements",
      "Doctor Consultation",
      "Lab Test and Doctor Consultation",
      "Referral Program",
      "Return & Refund Policy",
    ],
    route: [
      "/all-products/bonatra-x",
      "/all-products/reversal-program",
      "/all-products/supplements",
      "/all-products/consultation",
      "/package/starter",
      "/referral-program",
      "/return-refund-policy",
    ],
    queryid: [""],
  },

  {
    title: "LEGAL",
    labels: ["Terms of use", "Privacy Policy"],
    route: ["/tnc", "/tnc"],
    queryid: [""],
  },
  {
    title: "Browse Programs",
    labels: ["Bonatra Diabetes-Reversal Program", "Bonatra Fitness Program"],
    route: ["/programs/diabetes-reversal", "/programs/fitness"],
    queryid: [""],
  },
  {
    title: "FAQs",
    labels: ["Bonatra X1 Ring FAQs", "Bonatra Diabetes-Reversal Program FAQs"],
    route: ["/bonatra-x1-ring-faq", "bonatra-diabetes-reversal-program-faq"],
    queryid: [""],
  },
];

export default function handler(
  req: NextApiRequest,
  res: NextApiResponse<{ data: footerData }>
) {
  res.status(200).json({ data: footerData });
}
