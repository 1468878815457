import { useMutation } from "@tanstack/react-query";
import axios from "axios"

const useGoogleAuth = () => {
    return useMutation(async (access_token: string) => {
        const res = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.www.login.google_oauth2_login`, {
            access_token: access_token,
            use_jwt: 1,
            "device": "mobile"
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        })

        return res
    })
}

export default useGoogleAuth