'use-client'
import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import useLoginWithOTPLess from "@/lib/hooks/useLoginWithOTPLess";
import { useRouter } from "next/router"
import useZustandStore from "@/lib/store/useZustandStore";
import axios from "axios";
import useAuthStore from "../../lib/store/useAuthStore";
import useCheckLogin from '@/lib/hooks/useCheckLogin';


declare global {
    interface Window {
        otpless: (otplessUser: any) => void;
    }
}

const OTPLess = () => {
    const { mutate: loginWithOTPLessMutate } = useLoginWithOTPLess();
    const toast = useToast();
    const navigation = useRouter();
    const [isOTPLess, setIsOTPLess] = useState(false);
    const [otplessUID, setOtplessUID] = useState<string | null>(null);
    const { setUserData, setToken, setOtplessUserData, setLoginWithOTPLessMutateData, setIsItOtpless } = useAuthStore((state) => state);
    const setUser = useZustandStore((state) => state.setUser);
    const { data: isLoggedIn } = useCheckLogin();
    

    useEffect(() => { 
        const script = document.createElement('script');
        script.src = 'https://otpless.com/auth.js';
        script.id = 'otplessIdScript';
        document.body.appendChild(script);
    
        window.otpless = (otplessUser) => {
            setOtplessUserData(otplessUser);
            let payload = {
                "token": otplessUser?.token,
                "timestamp": otplessUser?.timestamp,
                "timezone": otplessUser?.timezone,
                "mobile": otplessUser?.mobile?.number,
                "wa_number": otplessUser?.waNumber,
                "wa_name": otplessUser?.waName,
                "device": "mobile",
                "use_jwt": 1
            }
            loginWithOTPLessMutate(
                { payload: payload },
                {
                    onSuccess: async (res: any) => {
                        setLoginWithOTPLessMutateData(res)
                        const TOKEN = res?.data?.token;
                        if (res.data.full_name) {
                            toast({
                                title: "OTPLess Login Successful",
                                description: "Welcome back, " + res.data.full_name,
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                                position: "top",
                            });
                            setOtplessUID(localStorage.getItem("uid"))
                        }
                        localStorage.setItem("token", TOKEN);
                        setToken(TOKEN);
                        localStorage.setItem("user", res?.data?.full_name);
                        localStorage.setItem("mobile_no", otplessUser?.waNumber)
                        localStorage.setItem('email', res?.data?.message?.data?.user)
                        let userInfoData
                        try {
                            userInfoData = await axios.get(
                                `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.custom.latest_customer_v2?user=${res?.data?.message?.data?.user}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                                    }
                                }
                            );
                        } catch (e) {
                            const errorResponse: any = e;
                            const errorMessage = errorResponse?.response?.data?.exception?.split(": ")[1];
    
                            if (errorMessage) {
                                console.log("New Customer Error: ", errorMessage);
                            } else {
                                console.log("New Customer Error: Unknown error");
                            }
                        }
                        setUserData(
                            userInfoData?.data?.message
                        );                     
                        setUser(res?.data?.message?.data?.user);          
                        navigation.push("/");
                        if(isOTPLess) {
                            if (isLoggedIn) {
                                if(navigation.asPath === '/login') {
                                    navigation.replace('/');
                                } else {
                                    navigation.back();
                                }
                            }
                        }
                    },
                    onError: (e) => {
                        const errorResponse: any = e;
                        const errorMessage = errorResponse?.response?.data?.exception?.split(": ")[1];
                        if (errorMessage === `User  not found with this mobile no ${payload?.wa_number}`) {
                            console.log("useLoginWithOTPLess Error:", errorMessage);
                            toast({
                                title: "User Not Found",
                                status: "error",
                                duration: 2000,
                                isClosable: true,
                                position: "top",
                            });
                            navigation.push("/signup");
                        } else {
                            console.log("useLoginWithOTPLess Error: ", e);
                            toast({
                                title: "Try Again",
                                description: "Something Went Wrong",
                                status: "error",
                                duration: 2000,
                                isClosable: true,
                                position: "top",
                            });
                            navigation.push("/signup");
                        }
                        
                    },
                }
            )
        }
        return () =>{
          console.log("home is unmounting")
          const divid =document.getElementById("otplessIdScript")
          if(divid && divid.parentNode) {
            divid.parentNode.removeChild(divid)
          }
        }
    },[])

    const handleLogin = () => {
        setIsOTPLess(true);
        setIsItOtpless(true);
    }
 
    return (
        <div className="bg-[#25D366] text-white text-lg text-center py-3 font-semibold rounded-lg cursor-pointer" id="otpless" data-custom="true" onClick={handleLogin}>Sign in with Whatsapp</div>
    );
};

export default OTPLess;